import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, removeStorageData,setStorageData } from "../../../framework/src/Utilities";
import { checkTokenExpired, filterListData } from "../../../components/src/CommonFunction";
import { checkPermission, checkLoggedInUser } from "../../CustomisableUserProfiles/src/utility.web"
import {WithStyles} from "@material-ui/core"
import DatePicker from "react-datepicker";
import React from "react";
export interface ToggleData{
  id:number,
  title:string,
  hidden:boolean

}
type IsPermit = [string, boolean];

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  location: any;
  openToastHandler: any;
  t: (key: string) => string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  statusModal: boolean;
  dateModal: boolean;
  startDate: any;
  endDate: any;
  anchorEl: any;
  teamMemberModal: any;
  toggleModal: boolean;
  information: boolean;
  purchases: boolean;
  status: string;
  suppliersData: any;
  getJobsLoading: boolean;
  sortDirection: any;
  sortColumn: any;
  jobsToggleData: any;
  statusToggleData: any;
  searchJobs: any;
  allJobs: any;
  statusName: string
  checkStatusName: string
  sigleStatusJob: any
  selectJobRowId: string | number
  hardDeleteStatus: boolean
  toasterStatus: boolean
  toasterText: string
  jobDeletedStatus: boolean
  hoverJobRow: number
  selectedLanguage: string
  selectedDate: {
    startDate: Date,
    endDate: null | Date
  },
  dateStatus: string,
  dayDuration:string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  datePickerRef: React.RefObject<DatePicker>
  // Customizable Area End
}

export default class JobsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchJobsApiCallId: string = "";
  jobsStatusApiCallId: string = "";
  deleteJobApiCallId: string = "";
  datePickerRef: React.RefObject<DatePicker>
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const JobsToggleData = [
      { id: 1, title: `${this.props.t('jobs.tableRow.title')}`, hidden: true },
      { id: 2, title: `${this.props.t('invoice.menuLabel.cusName')}`, hidden: true },
      { id: 3, title: `${this.props.t('jobs.tableRow.startEndDate')}`, hidden: true },
      { id: 4, title: `${this.props.t('jobs.tableRow.startEndTime')}`, hidden: true },
      { id: 5, title: `${this.props.t('Calendar.label.teamMembers')}`, hidden: true },
      { id: 6, title: `${this.props.t('invoice.menuLabel.productName')}`, hidden: false },
      { id: 7, title: `${this.props.t('invoice.menuLabel.serviceName')}`, hidden: false },
      { id: 8, title: `${this.props.t('invoice.menuLabel.totalPrice')}`, hidden: true },
    ]

    const StatusToggleData = [
      { id: 1, title: `${this.props.t('request.statusMenu.all')}`, statusName: "all", hidden: true },
      { id: 2, title: `${this.props.t('jobs.statusMenu.upcoming')}`, statusName: "upcoming", hidden: true },
      { id: 3, title: `${this.props.t('jobs.statusMenu.inProgress')}`, statusName: "in_progress", hidden: true },
      { id: 4, title: `${this.props.t('jobs.statusMenu.invoiced')}`, statusName: "invoiced", hidden: true },
      { id: 5, title: `${this.props.t('jobs.statusMenu.completed')}`, statusName: "completed", hidden: true },
      { id: 6, title: `${this.props.t('jobs.statusMenu.late')}`, statusName: "late", hidden: true },
      { id: 7, title: `${this.props.t('request.statusMenu.cancelled')}`, statusName: "cancelled", hidden: true },
    ]

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      statusModal: false,
      dateModal: false,
      anchorEl: null,
      teamMemberModal: null,
      startDate: new Date(),
      endDate: new Date(),
      toggleModal: false,
      information: false,
      purchases: false,
      status: "all",
      suppliersData: [],
      getJobsLoading: false,
      sortDirection: "asc",
      sortColumn: "",
      jobsToggleData: JobsToggleData,
      statusToggleData: StatusToggleData,
      searchJobs: "",
      allJobs: [],
      statusName: "All",
      checkStatusName: "All",
      sigleStatusJob: [],
      selectJobRowId: "",
      hardDeleteStatus: false,
      toasterStatus: false,
      toasterText: "",
      jobDeletedStatus: false,
      hoverJobRow: 0,
      selectedLanguage: '',
      selectedDate: {
        startDate: new Date(),
        endDate: null
      },
      dateStatus: `${this.props.t('dashboard.today')}`,
      dayDuration: "Today"
    };
    this.datePickerRef = React.createRef<DatePicker>()
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const tag = this.props.navigation?.history?.location?.state?.tag
    if(tag){
      this.setState({
        checkStatusName: tag,
        status: tag
      })
    } 
    const selectedLanguage = await getStorageData("lang");
    this.setState({ selectedLanguage: selectedLanguage })
    const statuse = localStorage.getItem('status') || this.props.t('dashboard.today')
    this.setState({dateStatus:statuse})
    this.handleJobSorting()
    window.scrollTo(0, 0);
    if (!localStorage.getItem(configJSON.storageToken)) {
      this.props.navigation.history.push("/Login");
    }
    this.handleJobSorting()
    const jobDeletedStatus = await getStorageData("jobArcheiveStatus")
    await removeStorageData("jobArcheiveStatus")
    if (jobDeletedStatus) {
      this.setState({ toasterStatus: true, toasterText: `${this.props.t('jobs.toasterText.deleted')}` }, () => {
        this.props.openToastHandler(this.state.toasterText, 'success')
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.jobsStatusApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.handleMessage(responseJson)
      } else {
        checkTokenExpired(responseJson, this.props.openToastHandler, this.props.navigation.history)
      }
    }
    if (apiRequestCallId === this.deleteJobApiCallId) {
      this.deleteJobApiResponce(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  getJobHeadingStyle = (keyValue: string) => {
    let textColor, dividerColor;
    switch (keyValue) {
        case "in_progress":
            textColor = "#FFA500";
            dividerColor = "#FFA500";
            break;
        case "completed":
            textColor = "#74AB39";
            dividerColor = "#74AB39";
            break;
        case "to_be_invoiced":
            textColor = "#008000";
            dividerColor = "#008000";
            break;
        case "upcoming":
            textColor = "#6764D6";
            dividerColor = "#6764D6";
            break;
        case "late":
            textColor = "#E02020";
            dividerColor = "#E02020";
            break;
        default:
            textColor = "#8C8C8C";
            dividerColor = "#8C8C8C";
            break;
    }
    return { textColor, dividerColor };
}

  handleMessage = (responseJson: any) => {
    if (responseJson.message === "you are not authorized") {
      this.setState({ getJobsLoading: false });
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    } else {
      this.setState({ getJobsLoading: false });
      this.setState({ allJobs: filterListData(responseJson) }, () => {
        this.handleStatusJobModalClose()
      })
    }
  }

  deleteJobApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState({
        toasterStatus: true,
        hardDeleteStatus: false,
        toasterText: `${this.props.t('jobs.toasterText.hardDeleteText')}`
      }, () => {
        this.props.openToastHandler(this.state.toasterText, 'success')
        this.handleJobSorting()
      })
    }
  }

  deleteJob = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.deleteJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.JobsEndPoint}/${this.state.selectJobRowId}?lang=${this.state.selectedLanguage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Api calls Ended
  handleChangeSearchJobs = (event: any) => {
    this.setState({ searchJobs: event.target.value }, () => {
      this.handleJobSorting();
    });
  }

  handleStatusJobModalOpen = () => {
    this.setState({
      statusModal: !this.state.statusModal,
      searchJobs: ""
    });
    this.setState({ toggleModal: false, dateModal: false });
  }

  handleDateModalOpen = () => {
    this.setState({
      dateModal: !this.state.dateModal,
    });
    this.setState({ toggleModal: false, statusModal: false });
  }

  handleStatusRequestModalClose = () => {
    this.setState({
      statusModal: false,
      statusName: this.state.checkStatusName
    }, () => {
      this.handleJobSorting()
    });
  }

  handleToggleJobModalOpen = () => {
    this.setState({ toggleModal: !this.state.toggleModal });
    this.setState({ statusModal: false, dateModal: false });
  }

  handleToggleRequestModalClose = () => {
    this.setState({ toggleModal: false });
  }

  handleJobSelectStatus = (job: any) => {
    const newToggleData = this.state.statusToggleData.map((item: any) => {
      if(job.statusName==='all'){
        return { ...item, hidden: !this.state.statusToggleData[0].hidden };
      }else{
        if (item.id === job.id) {
          return { ...item, hidden: !item.hidden };
        }
        return item;
      }
    });

    const anyHidden = newToggleData.some((item: any) => item.hidden);

    const selectedStatusNames = anyHidden
      ? newToggleData
        .filter((item: any) => item.hidden)
        .map((item: any) => item.statusName)
        .filter((stName:string) => stName !== 'all')
        .join(',')
      : "all";

    this.setState({
      statusToggleData: newToggleData,
      status: selectedStatusNames,
      checkStatusName: job.title
    }, () => {
      this.handleJobSorting();
    });
  }

  handleLanguageViewData = async (passed_category: string) => {
    const isEmployee = await checkLoggedInUser();
    const isPermit = await checkPermission(passed_category) as IsPermit;
    return this.handleViewDataCallback(isEmployee, isPermit);
  };


handleViewDataCallback = (isEmployee: string, isPermit: IsPermit) => {
  if (isEmployee) {
    return this.handleViewDataCallbackV1(isPermit)
  } else {
    return ["VIEW", "CREATE", "EDIT", "DELETE", "COMPLETE"];
  }
};

handleViewDataCallbackV1 = (isPermit: IsPermit) => {
  if (isPermit && !isPermit[1]) {
    return [];
  }

  switch (isPermit && isPermit[0]) {
    case this.props.t('jobs.None'):
      return ["NONE"];
    case this.props.t('jobs.ViewOnly'):
      return ["VIEW"];
    case this.props.t('jobs.ViewCreateEdit'):
      return ["VIEW", "CREATE", "EDIT"];
    case this.props.t('jobs.ViewCreateEditAndDelete'):
      return ["VIEW", "CREATE", "EDIT", "DELETE"];
    case this.props.t('jobs.ViewCompleteTasksAssignedThem'):
      return ["VIEW", "COMPLETE"];
    case this.props.t('jobs.ViewCreateEditAndDeleteTasksCreated'):
      return ["VIEW", "CREATE", "EDIT", "DELETE"];
    default:
      return this.handleViewDataCallbackV2(isPermit);
  }

}

handleViewDataCallbackV2 = (isPermit: IsPermit) => {
  if (isPermit && isPermit[0] === this.props.t('jobs.ViewOwnData')) {
    return ["VIEW"];
  }
  else if (isPermit && isPermit[0] === this.props.t('jobs.EditOwnData')) {
    return ["EDIT", "VIEW"];
  }
}


  handleCreateJob = async () => {
    const jobs = this.props.t("jobs.job")
    const isAuthorized = await this.handleLanguageViewData(jobs); //true = not authorized , false = authorized
    this.handleCreateJobCallback(isAuthorized)
  }

  handleCreateJobCallback = (isAuthorized: any) => {
    if (isAuthorized.includes("CREATE")) {
      this.props.navigation.history.push("/Job/Create");
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }

  handleSortJob = (columnKey: any) => {
    if (!(columnKey === "team" || columnKey === "product_name")) {
      let newSortDirection: "asc" | "desc" = "asc";
      if (columnKey === this.state.sortColumn) {
        newSortDirection = this.state.sortDirection === "asc" ? "desc" : "asc";
      }
      this.setState({
        sortDirection: newSortDirection,
        sortColumn: columnKey
      }, () => {
        this.handleJobSorting()
      });
    }
  };
  handleToggleColumnRequest = (id: any) => {
    const newToggleData = this.state.jobsToggleData.map((item: any) => {
      if (item.id === id) {
        return { ...item, hidden: !item.hidden };
      }
      return item
    })

    this.setState({
      jobsToggleData: newToggleData,
    });
  }
  handleCloseToasterDelete = () => {
    this.setState({ toasterStatus: false });
  }
  handleViewJob = async (item: string | number) => {
    const jobs = this.props.t("jobs.job")
    const isAuthorized = await this.handleLanguageViewData(jobs); 
    this.handleViewJobDataCallback(isAuthorized, item)
  }
   convertToArabicTime(englishTime: string | undefined) {
    if (!englishTime) return '';

    const arabicToEnglish: { [key: string]: string } = {
        '0': '٠', '1': '١', '2': '٢', '3': '٣', '4': '٤',
        '5': '٥', '6': '٦', '7': '٧', '8': '٨', '9': '٩'
    };
if(this.state.selectedLanguage === 'ar'){
  
    return englishTime.split('').map(char => arabicToEnglish[char] || char).join('');
}else{
  return englishTime;
}
}
  handleViewJobDataCallback = (isAuthorized: any, item: any) => {
    if (isAuthorized.includes("VIEW")) {
      this.props.navigation.history.push("/Job/Edit", {
        jobId: item,
        type: "edit"
      });
    } else {
      this.props.openToastHandler(`${this.props.t('invoice.BoAuthError')}`, "error");
    }
  }
  handleDeleteDailog = (JobId: string) => {
    this.setState({
      hardDeleteStatus: true,
      selectJobRowId: JobId

    })

  }
  handleDeleteCloseDailog = () => {
    this.setState({
      hardDeleteStatus: false,
    })
  }
  handleStatusJobModalClose = () => {
    this.setState({
      statusModal: false,
    })
  }

  handleDateModalClose = () => {
    this.setState({
      dateModal: false,
    })
  }

  handleSelectDateModalClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  handleSelectDateModalOpen = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleStatusModalClose = () => {
    const { t } = this.props;
    if (this.state.dateStatus !== t('dashboard.custom')) {
      this.setState({ dateModal: false });
    }
  }
  handleDateChange = (dates: [Date, null | Date]) => {
    const [startDate, endDate] = dates;
    this.setState({
      selectedDate: { ...this.state.selectedDate, startDate: startDate, endDate: endDate },
    }, () => {
      if (startDate && endDate) {
        this.handleJobSorting();
        this.setState({ getJobsLoading: true });
        this.setState({ dateModal: false });
      }
    });
  };
  handleSelectDateStatus = (item: { title: string; }) => {
    const { t } = this.props;
    const { startDate, endDate } = this.state.selectedDate;
    setStorageData("status",item.title)
    this.setState({ dateStatus: item.title }, () => {
      if (item.title === t('dashboard.custom')) {
        if (startDate && endDate) {
          this.handleJobSorting();
          this.setState({ getJobsLoading: true });
        }
      } else {
        this.handleJobSorting();
        this.setState({ getJobsLoading: true });
      }
    });
    if (item.title !== t('dashboard.custom')) {
      this.handleStatusModalClose()
    }
  }

  handleTeamModalClose = () => {
    this.setState({
      teamMemberModal: null,
    })
  }

  handleTeamModalOpen = (event: any) => {
    this.setState({
      teamMemberModal: event.currentTarget,
    })
  }

  handleStartDate = (date: any) => {
    this.setState({ startDate: date })
  }

  handleEndDate = (date: any) => {
    this.setState({ endDate: date })
  };

  statusAr = (status: string) => {
  
  let {dayDuration} =this.state
    if (status === "اليوم") {
      dayDuration = "today";
    } else if (status === "هذا الاسبوع") {
      dayDuration = "this_week";
    } else if (status === "هذا الشهر") {
      dayDuration = "this_month";
    } else if (status === "هذا العام") {
      dayDuration = "this_year";
    } else if (status === "مخصص") {
      dayDuration = "custom";
    }
    return dayDuration;
  }

  handleJobSorting = () => {
    const token = localStorage.getItem(configJSON.storageToken)
    const language = localStorage.getItem("lang");
    const { startDate, endDate } = this.state.selectedDate;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const apiEndPoint = configJSON.JobsEndPoint
    let jobsUrl
    if (this.state.searchJobs && this.state.sortColumn) {
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    } else if (this.state.searchJobs) {
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&query=${this.state.searchJobs}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus?.toLowerCase().replace(/ /g, "_")}`
    } else if (this.state.sortColumn) {
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&field=${this.state.sortColumn}&order=${this.state.sortDirection}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus) : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    } else {   
      jobsUrl = `${apiEndPoint}?status=${this.state.status}&lang=${language}&start_date=${startDate}&end_date=${endDate}&date_filter=${language === "ar" ? this.statusAr(this.state.dateStatus).toLowerCase().replace(/ /g, "_") : this.state.dateStatus.toLowerCase().replace(/ /g, "_")}`
    }
    this.setState(
      { getJobsLoading: true }
    )

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.jobsStatusApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), jobsUrl);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}

// Customizable Area End