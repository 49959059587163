import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Hidden,
  Container,
  Typography,
  Button,
} from "@material-ui/core";
import {
  Theme,
  styled,
  withStyles,
} from "@material-ui/core/styles";
import {
  arrowUp,
} from "./assets";
import { DashboardSideBar } from "./DashboardSideBar.web";
import { StyledRoot } from "./utility.web";
import withPermissions from "../../../components/src/withPermission.web";
import { withSnackbar } from "../../../components/src/toastweb";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
)
import LoadingBackdrop from "../../../components/src/LoadingBackdrop";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { CONSTANTS } from "../../../components/src/theme";
import { withTranslation } from "react-i18next";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
// Customizable Area End

import DashboardController, {
  Props,
} from "./DashboardController.web";

// Customizable Area Start
const paddingMap: Record<string, string> = {
  1: "0 1rem !important",
  2: "0 0 0 1rem !important",
  3: "0 3.5rem 0 1rem !important",
  4: "0 1.5rem 0 1rem !important",
};

const paddingMapAr: Record<string, string> = {
  1: "0 1.5rem !important",
  2: "0 0 0 1rem !important",
  3: "0px 1.5rem 0px 1rem !important",
  4: "0 1.5rem 0 1rem !important",
};

export const StyledTypography = styled(Typography)(({ theme, id }: any) => ({
  [theme.breakpoints.down("xs")]: {
    padding: (props) =>  props.languageSelected === "ar" ? paddingMapAr[id] || "0" : paddingMap[id] || "0",
  },
}));

const renderHr = (item: any, index: number) => {
  return (
    index !== item?.statusData?.length - 1 && <span style={webStyle.hr}></span>
  )
}

const cardMobileGrid = (id: number) => id === 3 ? 12 : 6

const cardCountStyle = (stateTitle: string, title: string) => {
  if(stateTitle === title) {
    return {...webStyle.card, border: `2px solid ${CONSTANTS.color.deepIndigo}`}
  } else {
    return webStyle.card
  }
}

const translations: Record<string, string> = {
  "Jan": "يناير", "Feb": "فبراير", "Mar": "مارس", "Apr": "أبريل", "May": "مايو", "Jun": "يونيو",
  "Jul": "يوليو", "Aug": "أغسطس", "Sep": "سبتمبر", "Oct": "أكتوبر", "Nov": "نوفمبر", "Dec": "ديسمبر",
  "0": "٠", "1": "١", "2": "٢", "3": "٣", "4": "٤", "5": "٥", "6": "٦", "7": "٧", "8": "٨", "9": "٩"
};

const titleMap: Record<string, string> = {
  Requests: "Total",
  Jobs: "All",
  Revenue: "Paid Invoices",
  "طلبات": "المجموع",
  "وظائف": "الجميع",
  "ربح": "الفواتير المدفوعة",
};
// Customizable Area End

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start  
  graphText = (title: string) => titleMap[title] || "";
  
  renderGraph = () => {
    return (
      <GoogleMap
        mapContainerStyle={webStyle.googleMaps}
        center={this.state.center}
        zoom={5}
        data-test-id="mapComponen"
        onLoad={(map) => { this.mapLoad(map) }}
        options={{
          disableDefaultUI: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          zoomControl:false
        }}
      >
        {this.state.markerPlaces?.map((marker: any, index: number) => (
          <Marker
            key={index}
            position={{
              lat: marker?.latitude,
              lng: marker?.longitude,
            }}
          />
        ))}
      </GoogleMap>
    );
  }

  renderStatusButton = (status: string, label: string) => {
    const isActive = this.state.status === status;
     console.log("status state ",this.state.status === status,"status ",this.state.status,"++",status)
    return (
      <Button
       data-test-id="mystatus-button"
        variant={isActive ? 'contained' : 'outlined'}
        style={{
          textTransform: 'capitalize' as const,
          backgroundColor: isActive ? CONSTANTS.color.deepIndigo : 'transparent',
          color: isActive ? 'white' : CONSTANTS.color.concreteGrey,
          borderRadius: "10px",
          border: "none"
        }}
      >
        {label}
      </Button>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props;
    
    window.addEventListener('resize', this.handleShowStatus);
    const { selectedStatus } = this.state
    const { classes }: any = this.props;
    return (
      <>
        <DashboardSideBar oldProps={undefined} history={undefined} {...this.props} navMenuItem={t('dashboardSidebar.dashboard')} active={1} active1={false} active2={false} />
        <StyledRoot languageSelected={this.state.languageSelected}>
          <Box
            style={webStyle.container}
            className={classes.container}
          >
            <Container maxWidth={false}>
              <CustomDatePicker
                data-test-id="customDatePicker"
                selectedDate={this.state.selectedDate}
                status={this.state.status}
                dateModal={this.state.dateModal}
                datePickerRef={this.datePickerRef}
                languageSelected={this.state.languageSelected}
                handleDateChange={this.handleDateChange}
                handleSelectStatus={this.handleSelectStatus}
                handleDateModalOpen={this.handleDateModalOpen}
                handleStatusModalClose={this.handleStatusModalClose}
              />
              <Grid container spacing={3} style={webStyle.gridCardContainer} className={classes.gridCardContainer}>
                {this.state.dashboardCard?.map((item: any) => (
                  <Grid item lg={4} md={4} xs={cardMobileGrid(item.id)} key={item.id}>
                    <Box
                      data-test-id="handleChangeTitle"
                      style={cardCountStyle(this.state.title, item.title)}
                      className={classes.card}
                      onClick={() => this.handleChangeTitle(item)}              
                    >
                      <Box style={webStyle.paddingStyle} className={classes.paddingStyle}>
                        <Typography style={webStyle.cardTitle} color="textPrimary" variant="subtitle1">
                          {item.title}
                        </Typography>
                        <Typography style={webStyle.cardCount} variant="h1">
                          {item.count}
                        </Typography>
                        <Box style={webStyle.percentageContainer} className={classes.percentageContainer}>
                          <Box style={webStyle.percentageContainer} className={classes.percentageInnerContainer}>
                            <img style={parseFloat(item.percentage) < 0 ? {...webStyle.icon, transform: "scaleY(-1)"} : webStyle.icon} src={arrowUp} alt="" />
                            <Typography style={webStyle.percentage} variant="subtitle2">
                              {item.percentage}
                            </Typography>
                          </Box>
                          <Typography style={webStyle.description} className={classes.description} component={'div'}>
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3} style={webStyle.graphContainer} className={classes.graphContainer}>
                <Grid item lg={8} md={12} xs={12}>
                  <Box style={webStyle.chartsContainer}>
                    <Box style={webStyle.chartsInnerContainer} className={classes.chartsInnerContainer}>
                      <Typography style={webStyle.chartTitle} className={classes.chartTitle} variant="h5">
                        {this.state.title}
                      </Typography>
                      <Box>
                        {this.renderStatusButton('This Week', 'Weekly')}
                        {this.renderStatusButton('This Month', 'Monthly')}
                        {this.renderStatusButton('This Year', 'Yearly')}
                      </Box>                   
                    </Box>
                    <Box style={webStyle.graphData} className={classes.graphData}>
                      <Line
                        style={webStyle.lineStyle}
                        data={this.state.graphData}
                        options={this.state.options}
                      />
                    </Box>
                    <Typography style={this.state.languageSelected === "ar" ? {...webStyle.graphText, gap: "5px"} : webStyle.graphText} component={'div'}>
                      <span
                        style={
                          this.state.title === "Requests" ||
                          this.state.title === "طلبات" ?
                          webStyle.dot :
                          {...webStyle.dot, backgroundColor: CONSTANTS.color.goldenApricot}
                        }
                      ></span>
                      {this.graphText(this.state.title)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4} md={12} sm={12} style={{direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}>
                  <Hidden xsDown>
                    {this.renderGraph()}
                  </Hidden>
                </Grid>
              </Grid>
              <Typography style={webStyle.statusTitle} className={`${classes.chartTitle} ${classes.statusText}`} variant="subtitle1">
                {t("dashboard.status")}
              </Typography>
              <Grid container spacing={3}>
                {this.state.statusData?.map((item: any) => {
                  const { title, statusData, backgroundColor, color } = item;
                  return (
                    <Grid item lg={3} md={6} sm={6} xs={12} key={item.id}>
                      <Box
                        style={webStyle.statusContainer}
                        className={classes.statusContainer}
                      >
                        <Box
                          data-test-id="handleClickStatus"
                          style={{
                            ...webStyle.titleContainer,
                            backgroundColor: backgroundColor,
                            borderRadius: !selectedStatus[item.id] ? "10px" : "10px 10px 0 0"
                          }}
                          className={classes.titleContainer}
                          onClick={() => this.handleClickStatus(item.id, item)}
                        >
                          <StyledTypography
                            style={{...webStyle.itemTitle, color: color}}
                            variant="subtitle1"
                            id={item.id}
                            languageSelected={this.state.languageSelected}
                          >
                            {title}
                          </StyledTypography>
                          <img className={classes.dropdownIcon} style={webStyle.dropdownIcon} src={item.icon} alt="" />
                        </Box>
                        {selectedStatus[item.id] && statusData?.map((i: any, index: number) => (
                          <Box key={i.id}>
                            <Box className={classes.statusInnerContainer} style={webStyle.statusInnerContainer} onClick={() => this.handleNavigate(i, item)}>
                              <Typography style={webStyle.statusTexts} component={'div'} className={classes.statusTexts}>
                                {i?.status?.toLowerCase()?.replace(/_/g, " ")?.replace(/\b\w/g, (c: string) => c?.toUpperCase())}
                              </Typography>
                              <Box style={webStyle.countContainer}>
                                <Typography style={webStyle.count} variant="body2">
                                  {i?.length || 0}
                                </Typography>
                              </Box>
                            </Box>
                            {renderHr(item, index)}
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={12} className={classes.graphMobile} style={{direction:localStorage.getItem('lang')==='ar'?'ltr':'rtl'}}>
                <Hidden smUp>
                  {this.renderGraph()}
                </Hidden>
              </Grid>
            </Container>
          </Box>
        </StyledRoot>
        <LoadingBackdrop
          loading={this.state.dashboardLoading}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles = (theme: Theme) => ({
  container: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 0.2rem 2rem !important",
    },
  },
  card: {
    
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none !important",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center !important",
    },
    "&:hover": {
    backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
    "& .MuiTypography-root": {
      color: `${CONSTANTS.color.white} !important`,
    },
    "& img": {
      filter: "brightness(0) invert(1)",
    },
  },
  },
  googleMaps: {
    [theme.breakpoints.down("md")]: {
      height: "auto !important"
    }
  },
  chartsInnerContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: '0rem 0.5rem 2rem !important'
    }
  },
  chartTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: '24px !important'
    }
  },
  statusText: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: '2rem !important'
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: '0rem !important'
    },
  },
  calendarMonthsContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "182px !important"
    }
  },
  graphData: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0.5rem 0.5rem 0.5rem !important"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px !important"
    },
  },
  graphContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem !important"
    }
  },
  statusTexts: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px !important"
    }
  },
  calendarContainer: {
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none !important"
    }
  },
  arrowsContainer: {
    [theme.breakpoints.down("xs")]: {
      gap: "1rem !important"
    }
  },
  percentageContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "block !important"
    }
  },
  percentageInnerContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center !important"
    }
  },
  description: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px !important",
      marginTop: "6px !important"
    }
  },
  paddingStyle: {
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 0 !important",
      alignItems: "center !important"
    }
  },
  gridCardContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "1rem !important",
    }
  },
  dropdownIcon: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important",
    }
  },
  graphMobile: {
    [theme.breakpoints.down("xs")]: {
      padding: "2rem 0 !important",
    }
  },
  statusContainer: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "0 !important",
    }
  },
  statusInnerContainer: {
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& img": {
        filter: "brightness(0) invert(1)",
      },
    }
  },
  titleContainer: {
    "&:hover": {
      backgroundColor: `${CONSTANTS.color.blueViolet} !important`,
      "& .MuiTypography-root": {
        color: `${CONSTANTS.color.white} !important`,
      },
      "& img": {
        filter: "brightness(0) invert(1)",
      },
    }
  }
});

const webStyle = {
  container: {
    marginTop: "8rem",
    paddingBottom: "4rem"
  },
  calendarContainer: {
    width: "100%",
    maxWidth: "280px",
    minHeight: "40px",
    borderRadius: "4px",
    border: `1px solid ${CONSTANTS.color.lightSilver}`,
    backgroundColor: CONSTANTS.color.pearlWhite,
    boxShadow: "0px 6px 7px rgba(28,24,175,0.05)",
  },
  arrowsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px"
  },
  arrow: {
    width: "8px",
    cursor: "pointer",
  },
  calendar: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    maxWidth: "226px",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: CONSTANTS.color.darkBlue,
    cursor: "pointer",
  },
  textWeek: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
  },
  verticalHr: {
    width: "1px",
    height: "100%",
    border: `1px solid ${CONSTANTS.color.blueGrey}`,
  },
  card: {
    display: "flex",
    width: "100%",
    minHeight: "177px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 16px 17px rgba(28,24,175,0.07)",
  },
  paddingStyle: {
    padding: "1rem",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  gridCardContainer: {
    paddingTop: "2rem"
  },
  cardTitle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    lineHeight: "20px",
    '&:hover': {
      backgroundColor: "red",
      // color: theme.palette.text.primary,
  },
  },
  cardCount: {
    color: CONSTANTS.color.blueViolet,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    wordBreak: "break-all" as const
  },
  percentageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
  },
  icon: {
    width: "17px",
    height: "22px",
  },
  percentage: {
    color: CONSTANTS.color.concreteGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "13px",
  },
  description: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "16px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
  },
  graphContainer: {
    paddingTop: "2rem"
  },
  chartsContainer: {
    width: "100%",
    minHeight: "336px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
  },
  chartsInnerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: '1rem 2rem 1rem 1rem'
  },
  calendarMonthsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    maxWidth: "226px",
    minHeight: "25px",
    borderRadius: "4px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 3px 14px rgba(0,0,0,0.05)",
  },
  weeklyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "68px",
    height: "25px",
    cursor: "pointer",
  },
  weeklyText: {
    color: CONSTANTS.color.steelBlueGrey,
    fontSize: "12px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "18px",
    textAlign: "center" as const,
  },
  dot: {
    display: "flex",
    width: "5px",
    height: "5px",
    borderRadius: "2.5px",
    backgroundColor: CONSTANTS.color.darkBlue,
    marginRight: "5px"
  },
  lineStyle: {
    maxHeight: "228px",
    color: CONSTANTS.color.steelBlueGrey,
    fontSize: "13px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "18px",
    textAlign: "center" as const,
  },
  graphData: {
    padding: "0px 1rem 0.5rem 1rem"
  },
  graphText: {
    color: CONSTANTS.color.steelBlueGrey,
    fontSize: "13px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "18px",
    textAlign: "center" as const,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chartTitle: {
    color: CONSTANTS.color.gunMetalGrey,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  titleContainer: {
    width: "100%",
    height: "88px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1.5rem",
    borderRadius: "10px 10px 0 0"
  },
  itemTitle: {
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
    textAlign: "center" as const,
  },
  statusTexts: {
    color: CONSTANTS.color.lightGrey,
    fontSize: "20px",
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    lineHeight: "20px",
  },
  countContainer: {
    width: "32px",
    height: "32px",
    backgroundColor: CONSTANTS.color.lightGrey,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  count: {
    color: CONSTANTS.color.white,
    fontWeight: CONSTANTS.fontWeight.fontWeight400,
    textAlign: "center" as const,
  },
  hr: {
    display: "flex",
    margin: "0 15px",
    height: "1px",
    border: `1px solid ${CONSTANTS.color.paleGrey}`,
  },
  dropdownIcon: {
    width: "16px",
    height: "8px"
  },
  statusInnerContainer: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.8rem 1rem"
  },
  googleMaps: {
    width: "100%",
    height: "336px",
    borderRadius: "10px",
  },
  statusTitle: {
    padding: "2rem 0 1rem 0",
    color: CONSTANTS.color.lightBrown,
    fontWeight: CONSTANTS.fontWeight.fontWeight500,
  },
  statusContainer: {
    width: "100%",
    minHeight: "568px",
    borderRadius: "10px",
    backgroundColor: CONSTANTS.color.white,
    boxShadow: "0px 6px 17px rgba(28,24,175,0.07)",
  },
}

const DashboardSnackBar = withStyles(styles)(withSnackbar(Dashboard))
const DashboardPermissions = withPermissions(DashboardSnackBar);
export default withTranslation()(DashboardPermissions);
// Customizable Area End